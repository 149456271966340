define("discourse/plugins/discourse-calendar/discourse/components/event-field", ["exports", "discourse-common/helpers/i18n", "truth-helpers/helpers/not-eq", "@ember/template-factory", "@ember/component", "@ember/component/template-only"], function (_exports, _i18n, _notEq, _templateFactory, _component, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const EventField = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    {{#if (notEq @enabled false)}}
      <div class="event-field" ...attributes>
        {{#if @label}}
          <div class="event-field-label">
            <span class="label">{{i18n @label}}</span>
          </div>
        {{/if}}
  
        <div class="event-field-control">
          {{yield}}
        </div>
      </div>
    {{/if}}
  
  */
  {
    "id": "MO3jYP/2",
    "block": "[[[1,\"\\n\"],[41,[28,[32,0],[[30,1],false],null],[[[1,\"    \"],[11,0],[24,0,\"event-field\"],[17,2],[12],[1,\"\\n\"],[41,[30,3],[[[1,\"        \"],[10,0],[14,0,\"event-field-label\"],[12],[1,\"\\n          \"],[10,1],[14,0,\"label\"],[12],[1,[28,[32,1],[[30,3]],null]],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n      \"],[10,0],[14,0,\"event-field-control\"],[12],[1,\"\\n        \"],[18,4,null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[\"@enabled\",\"&attrs\",\"@label\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "/build/source/app/assets/javascripts/discourse/discourse/plugins/discourse-calendar/discourse/components/event-field.js",
    "scope": () => [_notEq.default, _i18n.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  var _default = _exports.default = EventField;
});