define("discourse/plugins/discourse-calendar/discourse/connectors/before-topic-list-body/category-calendar", ["exports", "@glimmer/component", "@ember/template-factory", "@ember/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  class CategoryCalendar extends _component.default {
    static shouldRender(_1, ctx1) {
      return ctx1.siteSettings.calendar_categories_outlet === "before-topic-list-body";
    }
  }
  _exports.default = CategoryCalendar;
  _class = CategoryCalendar;
  (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="before-topic-list-body-outlet category-calendar"></div>
    
  */
  {
    "id": "dDOcuZzP",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"before-topic-list-body-outlet category-calendar\"],[12],[13],[1,\"\\n  \"]],[],false,[]]",
    "moduleName": "/build/source/app/assets/javascripts/discourse/discourse/plugins/discourse-calendar/discourse/connectors/before-topic-list-body/category-calendar.js",
    "isStrictMode": true
  }), _class);
});